<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>配件列表</el-breadcrumb-item>

    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-dialog title="导入人员档案" :visible.sync="importDialogVisible" width="66%">
        <el-upload
          ref="upload"
          name="file"
          :limit="limit"
          :auto-upload="false"
          action="http://dev.console.haiwojiamei.com/api/parts/excelimport"
          :on-exceed="handleExceed"
          :file-list="filelist"
          :on-change="handleChansge"
        >
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button
            style="margin-left: 10px;"
            size="small"
            type="success"
            @click="postfile"
            :disabled="btn.disable"
          >{{btn.message}}</el-button>
          <div slot="tip" class="el-upload__tip">上传文件只能为excel文件，且为xlsx,xls格式</div>
        </el-upload>
<!--        //此处可以写导入时出现的详细错误信息，包含第几行出现问题显示出来-->
        <div v-for="o in errmesg" :key="o.message" class="text item">{{ o.message }}</div>
      </el-dialog>

<!--      <el-button type="primary" @click='openchuan()'>上传</el-button>-->

      <el-row>
        <el-col :span="2">
          <el-button type="primary" @click='opentan()'>添加信息</el-button>
        </el-col>
        <el-col :span="6">
          <el-input
placeholder="请输入内容"
v-model.trim="pageData.key"
class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click='sosuode()'></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-upload
        action="www.baidu.com"
        show-file-list
        :http-request="updataparts"
        list-type="text"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :on-success='okla'
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          prop="parts_name"
          label="配件名称"
        >
        </el-table-column>
        <el-table-column
          prop="c_name"
          label="产品种类"
        >
        </el-table-column>
        <el-table-column
          prop="parts_model"
          label="型号"
        >
        </el-table-column>
        <el-table-column
          prop="num"
          label="数量"
        >
        </el-table-column>
        <el-table-column
          prop="parts_des"
          label="描述"
        >
        </el-table-column>
        <el-table-column
          prop="mj_part_no"
          label="嘉美部件号"
        >
        </el-table-column>

        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
            <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="120px" :rules="drules">
        <el-form-item label="配件名称" prop="parts_name">
          <el-input v-model="dform.parts_name"></el-input>
        </el-form-item>
        <el-row :gutter="0">
          <el-col :span="10">
            <el-form-item label="产品种类">
              <el-cascader
                v-model="idarr"
                :options="classdata"
                :props="cateListProps"
                @change="handfuleChange"></el-cascader>

            </el-form-item>

          </el-col>
          <el-col v-if="tantype!=='add'" :span="10">
            <el-form-item label="原产品种类：">
              <span>{{dform.c_name}}</span>
            </el-form-item>

          </el-col>

        </el-row>

        <el-form-item label="型号">
          <el-input v-model="dform.parts_model"></el-input>
        </el-form-item>

        <el-form-item label="描述">
          <el-input v-model="dform.parts_des"></el-input>
        </el-form-item>
        <el-form-item label="名称通用编号">
          <el-input v-model="dform.generality_bash"></el-input>
        </el-form-item>
        <el-form-item label="嘉美部件号">
          <el-input v-model="dform.mj_part_no"></el-input>
        </el-form-item>
        <el-form-item label="供应商部件号">
          <el-input v-model="dform.supplier_part_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button :loading="loading" v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import {
  AddParts,
  DelParts,
  EditParts,
  GetPartsList,
  GetOneParts,
  GetDrawingList, GetPartscategoryList, UpParts
} from '../../../api/vehicle/autoconfigure'
import { Upppp } from '../../../api/upimg'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      input3: '',
      importDialogVisible: false,
      // 文件
      file: '',
      filename: '',
      limit: 1,
      filelist: [],
      errmesg: [],
      btn: {
        disable: false,
        message: '上传服务器'
      },
      idarr: [], // 暂存选择
      loading: false,
      limitNum: 1,
      fileList: [],
      dialogVisible: false, // 弹窗控制
      dform: {
        parts_name: '', // 配件名称
        parts_des: '', // 配件描述
        num: '', // 配件数量
        parts_model: '', // 配件型号
        generality_bash: '', // 通用编号
        category_id: '', // 分类编号
        mj_part_no: '', // 嘉美部件号
        supplier_part_no: ''// 供应商部件号
      },
      // 弹窗按钮的状态
      tantype: 'add',
      // 产品中流数据
      classdata: [],
      // 级联选择配置
      cateListProps: {
        checkStrictly: true,
        value: 'id', // 匹配响应数据中的id
        label: 'c_name', // 匹配响应数据中的name
        children: 'children' // 匹配响应数据中的children
      },
      // 验证规则
      drules: {
        parts_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0,
        key: ''
      },
      // 列表数据
      tableData: []
    }
  },
  mounted() {
    this.getpartslistfun()
  },
  methods: {
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getpartslistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getpartslistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
      console.log(ev)
      this.idarr.push(ev.category_id)
    },
    lookfun(ld) { // 打开查看
      console.log(ld)
      this.onepartsfun(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delpartsfun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 搜索的
    sosuode() {
      this.pageData.page = 1,
        this.getpartslistfun()
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      this.getpartscategorylistfun()
    },
    // 父级选中的值
    handfuleChange(e) {
      this.dform.category_id = e[e.length - 1]
      console.log(e)
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addpartsfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editpartsfun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.tantype = 'add'
      this.idarr = []
    },
    handlePictureCardPreview(file) { // 上传时钩子

    },
    okla(response, file, fileList) { // 成功时
    },
    handleRemove(file, fileList) { // 移除的钩子
      // const arrindex = this.filearr.findIndex(item => item.name === file.name)
      // this.filearr.splice(arrindex, 1)

    },
    openchuan() {
      this.importDialogVisible = true
    },
    handleExceed(e) {
      // 判断是否只能上传一个文件，超过提示报错
      console.log(e)
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件哦'
      })
    },
    handleChansge(file, fileList) {
      console.log(file)
      const name = file.name
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$notify.error({
          title: '错误',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        this.filelist = []
        this.file = ''
        return false
      }
      this.file = file.raw
      this.filename = file.name
    },
    async postfile() {
      const that = this
      if (this.file == '') {
        that.$notify.error({
          title: '错误',
          message: '上传文件不能为空'
        })
        return false
      } else {
        // 文件形式的需要用 formData形式
        const formData = new FormData()
        console.log(this.file)
        formData.append('fname', this.file)
        const res = await UpParts({ fname: this.file })
        // 图片上传成功后把图片push到scroll中用于修改
        console.log(res)
      }
    },

    // 上传文件
    async updataparts(options) {
      console.log(options)
      this.loading = true
      const fd = new FormData()
      fd.append('fname', options.file)
      console.log(fd)
      const res = await Upppp(fd)
      // 图片上传成功后把图片push到scroll中用于修改
      console.log(res)

      if (res.status !== 200) {
        return this.$message.error('上传失败')
        // eslint-disable-next-line no-unreachable
        this.loading = false
      } else {
        // this.dform.fileList.push(res.data[0].url)

        // console.log(this.filearr)
        this.loading = false
      }
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取分类
    async getpartscategorylistfun() {
      const { data } = await GetPartscategoryList() // 注加参数全选

      this.classdata = data.data.list
      console.log(this.classdata)
    },
    // 获取配件
    async getpartslistfun() {
      const { data } = await GetPartsList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加配件
    async addpartsfun(v) {
      const { data } = await AddParts(v)
      this.getanpostfun(data, '添加', this.getpartslistfun())
    },
    // 删除配件
    async delpartsfun(id) {
      const { data } = await DelParts({ id })
      this.getanpostfun(data, '删除', this.getpartslistfun())
    },
    async editpartsfun(ev) {
      const { data } = await EditParts(ev)
      this.getanpostfun(data, '修改', this.getpartslistfun())
    },
    async onepartsfun(id) {
      const { data } = await GetOneParts({ id })
      this.dform = data.data.PartsInfo
      this.idarr.push(this.dform.category_id)
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>

</style>
